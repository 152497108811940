import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {header} from "./header.module.css"
import {useStaticQuery, graphql} from "gatsby"
import NonStretchedImage from "../utils/image"


const Header = ({siteTitle}) => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
          presentationWidth
        }
      }
    }
  }
`);

  return (
    <header className={header}>
        <Link className={header.link} to="/">
          <NonStretchedImage fluid={data.file.childImageSharp.fluid} critical={true} alt="Can't Code Logo" title="Can't Code Logo"/>
        </Link>
    </header>
)
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header
