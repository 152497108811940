import PropTypes from "prop-types"
import React from "react"
import {footer} from "./footer.module.css"


const Footer = ({siteTitle}) => (
  <footer className={footer}>
    © {new Date().getFullYear()}, Benjamin Houle

  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer
